/**
 * 搜索结果页
 */
import { mapMutations, mapState } from "vuex";
import { SOURCE_NAME } from "@/service/public/enum";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import { isPhone } from "@/utils/tools";
import DetailsAttribute from "@/components/DetailsAttribute/detailsAttribute.vue";
import Cookies from "js-cookie";
import { addGoodsCollectionService } from "@/service/goods-collection-service";
const Search = {
  name: "Search",
  components: {
    DetailsAttribute
  },
  computed: {
    ...mapState({
      searchEmptyList: (state) => state.search.searchEmptyList,
      searchRecommendList: (state) => state.search.searchRecommendList,
      menuType: (state) => state.product.menuType //获取状态管理菜单分类
    })
  },
  data() {
    return {
      isPhone: isPhone,
      content: ""
    };
  },
  created() {
    this.content = this.$route.query.content;
  },
  mounted() {
    document.documentElement.scrollTop = document.body.scrollTop = 0; //定位滚动条到头部
  },
  methods: {
    ...mapMutations("language", ["SET_CODE"]),
    ...mapMutations("system", [
      "SET_CURRENCY_CODE",
      "SET_COUNTRY_CODE",
      "SET_COUNTRY_LIST"
    ]),
    ...mapMutations("userCenter", [
      "SET_SELECT_MENU",
      "SET_USER_ACCOUNT",
      "DEL_ALERT_NEW"
    ]),
    ...mapMutations("product", [
      "SET_LETTER",
      "SET_SOURCE",
      "SET_PRODUCT_ID",
      "SET_NEW_SALE_NAME",
      "SET_LEVEL",
      "SET_LABEL",
      "SET_MENU_ID",
      "SET_BRAND_ID",
      "SET_IS_PARENT",
      "SET_SELECTION_ID",
      "SET_MENU_TYPE",
      "SET_SECOND_TYPE",
      "SET_TERTIARY_TYPE",
      "SET_LEVEL_TYPE",
      "SET_FIVE_TYPE",
      "SET_IS_NEW",
      "SET_IS_SALE",
      "SET_IS_OLD"
    ]),
    ...mapMutations("search", [
      "SET_SEARCH_EMPTY_LIST",
      "SET_SEARCH_RECOMMEND_LIST"
    ]),
    /**
     * 跳转列表页
     * @param obj
     */
    onProductList(obj, brand) {
      this.SET_LABEL(obj.coverTitle);
      this.SET_SELECTION_ID(obj.selectionId);
      this.SET_MENU_TYPE(this.menuType);
      this.SET_MENU_ID(this.menuType);
      this.SET_SOURCE(SOURCE_NAME.SUPER_SALE);
      this.SET_SECOND_TYPE({
        categoryId: "",
        name: ""
      });
      this.SET_TERTIARY_TYPE({
        categoryId: "",
        name: ""
      });
      this.searchVisible = false;
      if (brand === "brand") {
        this.SET_BRAND_ID(obj.brandId);
        this.SET_SOURCE(SOURCE_NAME.MENU_LINK_LIST);
        this.SET_IS_NEW("");
        this.SET_IS_SALE("");
        this.SET_IS_OLD("");
        this.SET_LEVEL("");
        if (this.$route.path === "/brands/list") {
          $event.$emit(EVENT_NAME.PRODUCT_BRANDS_LIST_QUERY);
          $event.$emit(EVENT_NAME.PRODUCT_BRANDS_MENU_QUERY);
        } else {
          this.$router.push("/brands/list");
        }
      } else {
        if (this.$route.path === "/product/list") {
          $event.$emit(EVENT_NAME.PRODUCT_LIST_QUERY);
          $event.$emit(EVENT_NAME.PRODUCT_MENU_QUERY);
        } else {
          this.$router.push("/product/list");
        }
      }
    },
    /**
     * 查询详情
     * @param id
     */
    onCarousel(id) {
      this.SET_PRODUCT_ID(id);
      this.$router.push("/product/details?id=" + id);
    },
    /**
     * 点击喜爱
     */
    onLike(obj) {
      if (!Cookies.get("token")) {
        $event.$emit(EVENT_NAME.HEADER_TIPS_STATUS);
        return;
      }
      this.onGoodsCollection(obj.id, obj.isWish).then();
    },
    /**
     * 新增商品收藏
     * @returns {Promise<void>}
     */
    async onGoodsCollection(id, type) {
      try {
        let params = {
          productId: id,
          type: type === 0 ? 1 : 0
        };
        const { code } = await addGoodsCollectionService(params);
        if (code === 1) {
          for (let i in this.searchRecommendList) {
            if (id === this.searchRecommendList[i].id) {
              //isWish
              type === 0
                ? (this.searchRecommendList[i].isWish = 1)
                : (this.searchRecommendList[i].isWish = 0);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  watch: {
    $route: {
      handler() {
        this.content = this.$route.query.content;
      },
      deep: true
    }
  },
  /**
   * 销毁事件
   */
  destroyed() {}
};
export default Search;

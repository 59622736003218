<template>
  <div class="layout-carousel">
    <a
      v-if="!isPhone()"
      href="javascript:"
      class="carousel-btn"
      :class="scroll === 0 ? 'carousel-grey-l' : 'carousel-black-l'"
      @click="onSwitchLeft"
    ></a>
    <div class="carousel-list" :id="id" @scroll="carouselScroll">
      <ul v-if="data" :style="'width:' + (data.length * 250 - 30) + 'px'">
        <li :key="key" v-for="(carousel, key) in data">
          <div class="list-handle">
            <a
              href="javascript:"
              class="handle-unified like"
              @click="onLike(carousel)"
              :class="carousel.isWish === 1 ? 'like' : 'like-grey'"
            ></a>
            <a
              href="javascript:"
              class="handle-unified star"
              v-if="carousel.isStar === 1"
            ></a>
            <a
              href="javascript:"
              class="handle-unified draft"
              v-if="carousel.isSole === 1"
            ></a>
          </div>
          <div class="carousel-image-container">
            <img
              class="carousel-image"
              @click="onLinks(carousel.id)"
              v-lazy="carousel.images"
            />
          </div>
          <div class="carouse-text">
            <p class="carouse-content brand-name">{{ carousel.brandName }}</p>
            <p class="carouse-content">
              {{
                currencyCode === "cny"
                  ? onCharacterPrice(carousel.price)
                  : carousel.price
              }}
            </p>
          </div>
        </li>
      </ul>
    </div>
    <a
      href="javascript:"
      class="carousel-btn"
      v-if="!isPhone() && data && data.length !== 0"
      :class="
        scroll >= data.length * 250 - 30 - offsetWidth
          ? 'carousel-grey-r'
          : 'carousel-black-r'
      "
      @click="onSwitchRight"
    ></a>
  </div>
</template>
<script>
import DetailsAttribute from "./detailsAttribute";
export default DetailsAttribute;
</script>
<style lang="less" scoped>
@import "detailsAttribute";
</style>

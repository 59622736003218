<template>
  <div class="layout-search">
    <!--s: search-->
    <div class="layout-search-tips">
      <div class="box">
        <h1 class="title">
          {{ $t("carousel.searchTipsTitle", { search: content }) }}
        </h1>
        <div
          class="text"
          v-html="$t('carousel.searchTipsText', { search: content })"
        ></div>
      </div>
      <div class="search-tips-list" v-if="searchEmptyList.length || !isPhone()">
        <ul>
          <li :key="key" v-for="(empty, key) in searchEmptyList">
            <img
              @click="onProductList(empty)"
              :src="empty.coverImage"
              v-lazy="empty.coverImage"
            />
            <h3 class="recommend-name">{{ empty.coverTitle }}</h3>
          </li>
        </ul>
      </div>
      <!--s: title-->
      <div class="layout-title">
        <h1 class="title-name">{{ $t("headline.recommend") }}</h1>
        <div class="title-box">
          <a class="title-link" href="javascript:"
            >{{ $t("headline.more") }}<i class="icon"></i
          ></a>
        </div>
      </div>
      <!--e: title-->
      <div class="attribute-body">
        <DetailsAttribute
          :data="searchRecommendList"
          id="searchContentId"
          @on-links="onCarousel"
          @on-like="onLike"
        />
      </div>
    </div>
    <!--e: search-->
  </div>
</template>
<script>
import Search from "./search";
export default Search;
</script>
<style lang="less">
@import "search";
</style>

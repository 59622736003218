import request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'

/**
 * get 新增商品收藏
 * @param params
 * @returns {Promise<*>}
 */
export const addGoodsCollectionService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/wish/setWish?productId='+params.productId+'&type='+params.type))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 查询用户商品收藏列表
 * @param params
 * @returns {Promise<*>}
 */
export const getGoodsCollectionListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/wish/get/list?cid1='+params.cid1+'&page='+params.page+'&size='+params.size))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 删除全部商品收藏
 * @param params
 * @returns {Promise<*>}
 */
export const delAllGoodsCollectionService = async () => {
    try {
        const { data } = await request.delete(evnUrl.concat('/shop/wish'))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 查询用户偏好
 * @param params
 * @returns {Promise<*>}
 */
export const getUserPreferenceService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/preference'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 设置用户偏好
 * @param params
 * @returns {Promise<*>}
 */
export const addUserPreferenceService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/shop/preference/setPreference'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
